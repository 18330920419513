import React from 'react';
import MenuBackground from './components/MenuBackground';
import QrCode from './components/QrCode';

function App() {
  return (
    <div className="App">
      <MenuBackground />
      <QrCode />
    </div>
  );
}

export default App;
