import React from 'react';
import QRCode from 'qrcode.react';
import logo from '../assets/logo.jpg'; // Asegúrate de que el logo esté en la carpeta src/assets

const QrCode = () => {
  return (
    <div className="flex justify-center items-center py-10">
      <QRCode
        value="http://matuko.ssdevsolutions.com"
        size={256}
        bgColor="#ffffff"
        fgColor="#000000"
        level="Q"
        includeMargin={true}
        imageSettings={{
          src: logo,
          x: null,
          y: null,
          height: 60,
          width: 60,
          excavate: true,
        }}
      />
    </div>
  );
};

export default QrCode;
