import React from 'react';
import logo from '../assets/logo.jpg'; // Asegúrate de que el logo esté en la carpeta src/assets

const MenuBackground = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 py-10">
      <div className="flex flex-col w-full bg-white shadow-lg mx-4 sm:mx-0" style={{ height: 'auto', width: '100%', maxWidth: '8.5in' }}>
        <style>
          {`
            @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Playwrite+NZ:wght@100..400&display=swap');

            .font-display {
              font-family: 'Playwrite NZ', cursive;
            }

            .font-sans {
              font-family: 'Inter', sans-serif;
            }
          `}
        </style>
        
        <div className="flex flex-col md:flex-row h-full">
          <div className="w-full md:w-1/4 bg-black text-white p-6 flex flex-col justify-between">
            <div>
              <div className="flex flex-col items-center mb-4">
                <img src={logo} alt="Logo" className="w-3/4 mb-4" />
                <h2 className="text-xl font-bold">Especialidades</h2>
                <ul className="mb-4 text-sm">
                  <li className="border-b py-2">Torta de Camarón $99</li>
                  <li className="border-b py-2">Atún Sellado $99</li>
                  <li className="border-b py-2">Atún Matuko $129</li>
                  <li className="border-b py-2">Tiradito de Atún $89</li>
                </ul>
              </div>
              <div className="flex flex-col items-center mb-4">
                <img src={logo} alt="Logo" className="w-3/4 mb-4" />
                <h2 className="text-xl font-bold">Fin de Semana</h2>
                <ul className="mb-4 text-sm">
                  <li className="border-b py-2">Pulpo Zarandeado $249</li>
                  <li className="border-b py-2">Camarones Zarandeados $169</li>
                  <li className="border-b py-2">Brochetas de Camarón $39</li>
                  <li className="border-b py-2">Caldo de pescado con trozo $99</li>
                </ul>
              </div>
              <div className="flex flex-col items-center mb-4">
                <img src={logo} alt="Logo" className="w-3/4 mb-4" />
                <h2 className="text-xl font-bold">Promociones</h2>
                <ul className="mb-4 text-sm">
                  <li>2x1 en ceviches los lunes</li>
                  <li>Happy Hour de 4-6 PM todos los días</li>
                  <li>Descuento del 10% en tu primera visita</li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <img src={logo} alt="Logo" className="w-3/4 mb-4" />
              <h2 className="text-xl font-bold">Contacto</h2>
              <p>Dirección: Calle Falsa 123, Ciudad</p>
              <p>Teléfono: (123) 456-7890</p>
              <p>Email: contacto@restaurante.com</p>
            </div>
          </div>

          <div className="w-full md:w-3/4 p-6 font-sans text-black">
            <div className="text-center">
              <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                <h2 className="text-2xl font-bold text-orange-700 font-display">CAMARONES</h2>
              </div>
              <ul className="mb-4">
                <li className="border-b py-2">Mantequilla, Mojo de Ajo, Diabla, Ajillo, Hawaianos y Empanizados $139</li>
                <li className="border-b py-2">Momia $169</li>
                <li className="border-b py-2">Coco $169</li>
              </ul>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 pr-2 text-center mb-4 md:mb-0">
                <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                  <h2 className="text-2xl font-bold text-orange-700 font-display">CEVICHES</h2>
                </div>
                <ul className="mb-4">
                  <li className="border-b py-2">Molido 1/2 $80 1L $150 Tostada $25</li>
                  <li className="border-b py-2">Trozo 1/2 $90 1L $170 Tostada $30</li>
                  <li className="border-b py-2">Camarón 1/2 $100 1L $190 Tostada $35</li>
                  <li className="border-b py-2">Atún 1/2 $90 1L $170 Tostada $30</li>
                  <li className="border-b py-2">Marlin 1/2 $90 1L $170 Tostada $30</li>
                </ul>
              </div>
              <div className="w-full md:w-1/2 pl-2 text-center">
                <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                  <h2 className="text-2xl font-bold text-orange-700 font-display">AGUACHILES</h2>
                </div>
                <ul className="mb-4">
                  <li className="border-b py-2">Verde 1/2 $109 1L $199</li>
                  <li className="border-b py-2">Negro 1/2 $109 1L $199</li>
                  <li className="border-b py-2">Rojo 1/2 $109 1L $199</li>
                  <li className="border-b py-2">Mango Habanero 1/2 $109 1L $199</li>
                  <li className="border-b py-2">Aguachile Matuko 1/2 $159 1L $279</li>
                </ul>
              </div>
            </div>

            <div className="text-center">
              <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                <h2 className="text-2xl font-bold text-orange-700 font-display">CONCHAS</h2>
              </div>
              <ul className="mb-4">
                <li className="border-b py-2">Almeja Reyna Matuko $99</li>
                <li className="border-b py-2">Pata de Mula 1/2 $55 Docena $89</li>
                <li className="border-b py-2">Ostión 1/2 $55 Docena $89</li>
              </ul>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 pr-2 text-center mb-4 md:mb-0">
                <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                  <h2 className="text-2xl font-bold text-orange-700 font-display">TACOS</h2>
                </div>
                <ul className="mb-4">
                  <li className="border-b py-2">Gobernador $29</li>
                  <li className="border-b py-2">Capeado de pescado $39</li>
                  <li className="border-b py-2">Capeado de camarón $49</li>
                  <li className="border-b py-2">Matuko $69</li>
                  <li className="border-b py-2">Marlin $39</li>
                </ul>
              </div>
              <div className="w-full md:w-1/2 pl-2 text-center">
                <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                  <h2 className="text-2xl font-bold text-orange-700 font-display">BURROS</h2>
                </div>
                <ul className="mb-4">
                  <li className="border-b py-2">Camarón $99</li>
                  <li className="border-b py-2">Burro Matuko $129</li>
                </ul>
              </div>
            </div>

            <div className="text-center">
              <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                <h2 className="text-2xl font-bold text-orange-700 font-display">FILETES AL GUSTO</h2>
              </div>
              <ul className="mb-4">
                <li className="border-b py-2">Mantequilla, Mojo de Ajo, Diabla, Ajillo y Empanizado $129</li>
              </ul>
            </div>

            <div className="text-center">
              <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                <h2 className="text-2xl font-bold text-orange-700 font-display">COCTELES</h2>
              </div>
              <ul className="mb-4">
                <li className="border-b py-2">Camarón Cocido $139</li>
                <li className="border-b py-2">Camarón y Pulpo $189</li>
              </ul>
            </div>

            <div className="text-center">
              <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                <h2 className="text-2xl font-bold text-orange-700 font-display">ENSALADAS</h2>
              </div>
              <ul className="mb-4">
                <li className="border-b py-2">Pulpo 1/2 $129 1L $239</li>
                <li className="border-b py-2">Surimi 1/2 $79 1L $149</li>
              </ul>
            </div>

            <div className="text-center">
              <div className="border-b-2 border-t-2 border-orange-700 py-2 mb-4">
                <h2 className="text-2xl font-bold text-orange-700 font-display">HAMBURGUESAS</h2>
              </div>
              <ul className="mb-4">
                <li className="border-b py-2">Res $79</li>
                <li className="border-b py-2">Camarón $99</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBackground;
